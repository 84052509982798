// import Head from 'next/head'
import Script from 'next/script'

const ganalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
const ganalyticsSrc = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${ganalyticsId}');
`

const AnalyticsHead = () => (
	<>
		<Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${ganalyticsId}`} />
		<Script dangerouslySetInnerHTML={{ __html: ganalyticsSrc }} />
	</>
)
export default AnalyticsHead