import { useEffect } from 'react'

import { GoogleOAuthProvider } from '@react-oauth/google'

import { ChakraProvider } from '@chakra-ui/react'

import AnalyticsHead from '../components/head/AnalyticsHead'

import 'focus-visible/dist/focus-visible'

import '../styles/globals.css'
import '../styles/react-flow.css'

const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID

const AppWithStore = ({ Component, pageProps }) => {  
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ChakraProvider>
        <Component {...pageProps} />
      </ChakraProvider>
    </GoogleOAuthProvider>
  )
}

function MyApp({ Component, pageProps, router }) {

  useEffect(()=> {
    const rootElement = document.getElementById('__next')
    rootElement.style.position = router.pathname === '/' ?  'fixed' : 'none'
  },[router.pathname])

  return (
    <>
      <AnalyticsHead/>
      <AppWithStore Component={Component} pageProps={pageProps}/>
    </>
  )
}

export default MyApp
